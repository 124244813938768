import { Language } from '@/app/providers/translate/languages'
import useAuthApi from '@/shared/api/auth/authApi'
import {
  Api3_JwtResponseDto,
  Api3_JwtResponseUserDto,
  Api3_LightRealmWithRolesDto,
  Api3_UserActivityDTO,
} from '@/shared/api/types/api-v3-service-auth'
import { UserAuthority, UserRole } from '@/shared/types/roles'
import { EmitterEvent, useEventEmitter } from '@/shared/utils/event-emitter/useEventEmitter'
import { decodeJwt, getJtiFromToken } from '@/shared/utils/misc-utils/jwt'
import { getSystemLanguage } from '@/shared/utils/vue-utils/getSystemLanguage'
import { getAuthorities, UserPermission } from '@/store/auth/user-store/permissions'
import { OkPromise } from '@/store/common/base-ui/BaseUiInterface'
import { useBaseUi } from '@/store/common/base-ui/useBaseUi'
import axios from 'axios'
import { defineStore } from 'pinia'
import { computed, ComputedRef, defineAsyncComponent, ref } from 'vue'
import UserStoreInterface from './UserStoreInterface'
import formatString from '@/shared/utils/str-utils/formatString'

type Theme = 'light' | 'dark'

const SettingsDialog = defineAsyncComponent(() => import('@/components/auth/settings-dialog/index.vue'))
const UserProfileDialog = defineAsyncComponent(() => import('@/components/auth/user-profile-dialog/index.vue'))
const LicenseInfoDialog = defineAsyncComponent(() => import('@/components/auth/license-info-dialog/index.vue'))

export const useUserStore = defineStore('user', (): UserStoreInterface => {
  const api = useAuthApi()

  const eventEmitter = useEventEmitter()
  const { openDialog, showWarning } = useBaseUi()

  // state
  const _logged = ref(false)
  const _realmGuid = ref('')
  const _accessToken = ref('')
  const _refreshToken = ref('')
  const _lang = ref(getSystemLanguage())
  const _userData = ref({} as Api3_JwtResponseUserDto)
  const _userActivity = ref([] as Api3_UserActivityDTO[])
  const _debugMode = ref(false)
  const _theme = ref('light' as Theme)
  const userName = ref('')
  let _sessionId = ''

  // getters
  const logged = computed(() => _logged.value)
  const lang = computed(() => _lang.value)
  const theme = computed(() => _theme.value)
  const debugMode = computed(() => _debugMode.value)
  const userData = computed(() => _userData.value)
  const userActivity = computed(() => _userActivity.value)
  const userRealms: ComputedRef<Api3_LightRealmWithRolesDto[]> = computed(() =>
    (_userData.value.realms || []).sort((a, b) => a.presentation_name.localeCompare(b.presentation_name)),
  )
  const _masterRealm: ComputedRef<Api3_LightRealmWithRolesDto | undefined> = computed(() =>
    (_userData.value.realms || []).find(realm => realm.roles.some(r => r.name === 'ROLE_MASTER_ADMIN')),
  )
  const realmGuid = computed(() => {
    const realm = userRealms.value.find(r => r.guid === _realmGuid.value)
    return realm ? realm.guid : ''
  })
  const realmName = computed(() => {
    return userRealms.value.find(r => r.guid === _realmGuid.value)?.name
  })
  const isAdmin = computed(() => !_masterRealm.value && hasRole(UserRole['ROLE_REALM_ADMIN']))
  const isMasterAdmin = computed(() => _userData.value.isMasterAdmin)

  // actions
  function getFirstRealmGuid() {
    if (userRealms.value.length === 0) return ''
    return userRealms.value[0].guid
  }

  function changePassword(oldPassword: string, newPassword: string) {
    return api.changePassword(oldPassword, newPassword)
  }

  function _loadFromStorage() {
    _lang.value = getSystemLanguage()
    _theme.value = (localStorage.getItem('theme') as Theme) || 'light'
    _accessToken.value = localStorage.getItem('access_token') || ''
    if (_accessToken.value) {
      _logged.value = true
      _refreshToken.value = localStorage.getItem('refresh_token') || ''
      _realmGuid.value = localStorage.getItem('realmGuid') || ''
      _userData.value = JSON.parse(localStorage.getItem('userData') || '{}')
      _lang.value = (_userData.value.locale as any) || getSystemLanguage()
      _debugMode.value = localStorage.getItem('debug') === 'true'
      _sessionId = getJtiFromToken(_accessToken.value)
      userName.value = getUserName(_accessToken.value)
    }
    _updateApiHeaders()
  }

  function getUserName(accessToken: string): string {
    const decoded = decodeJwt(accessToken) || {}
    return decoded.sub
  }

  async function loginUser(username: string, password: string): Promise<void> {
    const { user, refresh_token, access_token } = await api.loginUser(username, password)
    console.log(user)
    _logged.value = true
    _userData.value = user
    _lang.value = user.locale as any
    _accessToken.value = access_token
    _refreshToken.value = refresh_token
    _realmGuid.value = ''
    _sessionId = getJtiFromToken(access_token)
    userName.value = username
    _saveToStorage()
  }

  function clear() {
    _logged.value = false
    _realmGuid.value = ''
    _accessToken.value = ''
    _refreshToken.value = ''
    _sessionId = ''
    _userData.value = {} as Api3_JwtResponseUserDto
    _saveToStorage()
    eventEmitter.emit(EmitterEvent.LOGOUT)
  }

  async function logoutUser(): Promise<void> {
    try {
      if (!_logged.value) return
      await changeDomain(undefined, realmGuid.value)
      resetApiHeader()
      await api.logoutUser()
    } catch {
      //
    } finally {
      clear()
    }
  }

  function restorePassword(username: string): Promise<void> {
    return api.restorePassword(username)
  }

  function verifyPassword(user_guid: string, new_password: string, verify_code: string): Promise<void> {
    return api.verifyPassword(user_guid, new_password, verify_code)
  }

  function _saveToStorage() {
    localStorage.setItem('realmGuid', isMasterAdmin.value ? getFirstRealmGuid() : _realmGuid.value)
    localStorage.setItem('userData', JSON.stringify(_userData.value))
    localStorage.setItem('access_token', _accessToken.value)
    localStorage.setItem('refresh_token', _refreshToken.value)
    localStorage.setItem('lang', _lang.value)
    localStorage.setItem('theme', _theme.value)
    localStorage.setItem('debug', String(_debugMode.value))

    _updateApiHeaders()
  }

  function resetApiHeader() {
    const headers = (axios as any).defaults.headers.common
    delete headers.Realm_guid
  }

  function _updateApiHeaders() {
    const headers = (axios as any).defaults.headers.common
    if (_logged.value) {
      headers['Authorization'] = `Bearer ${_accessToken.value}`
      headers['Realm_guid'] = realmGuid.value
      headers['Session'] = _sessionId
      headers['Language'] = _lang.value
    } else {
      delete headers.Authorization
      delete headers.Realm_guid
      delete headers.Session
      delete headers.Language
    }
  }

  function updateRealm(guid: string): void {
    changeDomain(guid, realmGuid.value).catch()
    _realmGuid.value = guid
    _saveToStorage()
  }

  function updateSettings(options: { lang?: Language; theme?: Theme }) {
    if (options.lang) {
      _lang.value = options.lang
      _userData.value.locale = options.lang as any
    }
    _saveToStorage()
  }

  function updateDebugMode(value: boolean) {
    _debugMode.value = value
    _saveToStorage()
  }

  function hasAuthority(authority: UserAuthority | string): boolean {
    if (!_logged.value) return false
    const realm = userRealms.value.find(x => x.guid === realmGuid.value)
    if (!realm) return false
    const result = realm.authorities.includes(authority)
    if (!result) {
      console.log('Отказ в правах, authority: ' + authority)
    }
    return result
  }

  function hasPermission(permission: UserPermission): boolean {
    const result = getAuthorities(permission).every(hasAuthority)
    if (!result) {
      console.log('=== permission: ' + permission)
    }
    return result
  }

  function hasRole(userRole: UserRole) {
    if (!_logged.value || !_realmGuid.value) return false
    const currentRealm = (_userData.value.realms || []).find(realm => realm.guid === _realmGuid.value)
    if (!currentRealm) return false
    return Boolean(currentRealm.roles.find(role => role.name === userRole))
  }

  function _updateTokens({ access_token, refresh_token }: Api3_JwtResponseDto) {
    _accessToken.value = access_token
    _refreshToken.value = refresh_token
    _saveToStorage()
  }

  async function loadUserActivity() {
    _userActivity.value = await api.fetchAllUsersActivity()
  }

  function openSettingsDialog(): OkPromise {
    return openDialog({
      component: SettingsDialog,
    })
  }

  function openUserProfileDialog(): OkPromise {
    return openDialog({
      component: UserProfileDialog,
    })
  }

  function openLicenseInfoDialog(): void {
    openDialog({
      component: LicenseInfoDialog,
    })
  }

  function init() {
    _loadFromStorage()
    eventEmitter.on(EmitterEvent.TOKENS_UPDATED, _updateTokens)
    // if (_logged.value) {
    //   fetchLicense()
    //     .then(license => showLicenseInfo(license.expiration_date))
    //     .catch()
    // }
  }

  async function changeDomain(to: string | undefined, from: string | undefined): Promise<void> {
    if (isMasterAdmin.value || to === from) return

    if (from) {
      await api.exitFromDomain(from)
      eventEmitter.emit(EmitterEvent.DOMAIN_EXIT)
    }

    if (to) {
      await api.enterToDomain(to)
      eventEmitter.emit(EmitterEvent.DOMAIN_ENTER)
    }
  }

  function getDaysUntil(dateString: string) {
    const dateFormat = /^\d{2}-\d{2}-\d{4}$/
    if (!dateFormat.test(dateString)) return null

    const [day, month, year] = dateString.split('-').map(Number)
    const targetDate = new Date(year, month - 1, day)
    const currentDate = new Date()
    const differenceInTime = targetDate.getTime() - currentDate.getTime()
    return Math.ceil(differenceInTime / (1000 * 3600 * 24))
  }

  function getDaysStr(N: number) {
    N = Math.abs(N)
    const lastDigit = N % 10
    const lastTwoDigits = N % 100

    if (lastTwoDigits >= 11 && lastTwoDigits <= 19) return `дней`

    if (lastDigit === 1) {
      return `день`
    } else if (lastDigit >= 2 && lastDigit <= 4) {
      return `дня`
    } else {
      return `дней`
    }
  }

  function showLicenseInfo(date: string | null): void {
    if (!date) return
    const days = getDaysUntil(date)
    if (days !== null && days >= 0 && days <= 30) {
      const msg = formatString($translate.licenseExpireInDays, days)
      showWarning(msg.replace('[DAYS]', getDaysStr(days)))
    }
  }

  // expose

  return {
    logged,
    userData,
    userRealms,
    userActivity,
    isMasterAdmin,
    isAdmin,
    realmGuid,
    realmName,
    lang,
    theme,
    debugMode,
    userName,
    clear,
    updateRealm,
    updateSettings,
    updateDebugMode,
    restorePassword,
    verifyPassword,
    loginUser,
    logoutUser,
    changePassword,
    hasAuthority,
    hasPermission,
    hasRole,
    loadUserActivity,
    openSettingsDialog,
    openUserProfileDialog,
    openLicenseInfoDialog,
    init,
    changeDomain,
    getFirstRealmGuid,
    showLicenseInfo,
  }
})
